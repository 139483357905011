@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue-Medium.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue-Bold.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../../dist/font/HelveticaNeue.woff2') format('woff2'),
		url('../../dist/font/HelveticaNeue.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background-color: #fbfbfb;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Helvetica Neue';
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	background-color: #fbfbfb;
	overflow-x: hidden;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

h1,
h2,
h3,
h4,
h5,
ul,
ol,
p {
	margin: 0;
}

p {
	font-size: 16px;
	line-height: 1.4;
}

.hide {
	display: none;
}

.mobile {
	@include tablet-up {
		display: none !important;
	}
}

.desktop {
	@include tablet-down {
		display: none !important;
	}
}

.socials {
	@include flex(row, center, center);
	gap: 20px;

	a {
		font-size: 20px;
	}
}

.split {
	@include flex(column, initial, initial);

	.side {
		flex: 1;
	}

	@include tablet-up {
		@include flex(row, initial, initial);
	}
}

.menu-trigger {
	order: 2;
	display: block;
	border: none;
	background-color: transparent;
	border: none;
	border-style: none;
	border: solid 2px transparent;
	border-top-color: $white;
	border-bottom-color: $white;
	position: relative;
	width: 40px;
	height: 25px;
	transition: all .4s;
	margin-left: auto;

	&:before,
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 2px;
		top: calc(50% - 1px);
		left: 0;
		position: absolute;
		background-color: $white;
		transition: all .4s;
	}

	&.active {
		border-color: transparent;

		&::after {
			transform: rotate(45deg);
		}

		&::before {
			transform: rotate(-45deg);
		}
	}
}

.btn {
	display: inline-block;
	background-color: transparent;
	border: solid 1px transparent;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 600;
	padding: 12px 20px;

	&.btn-black {
		background-color: $black;
		color: $white;

		@include hover-focus {
			background-color: $gray;
		}
	}

	&.btn-blue {
		background-color: $blue;
		color: $white;

		@include hover-focus {
			background-color: $gray;
		}
	}
}

.hide {
	display: none !important;
}

.has-bg {
	position: relative;

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.content {
		position: relative;
	}
}

.slick-slider {
	position: relative;

	.slick-arrow {
		position: absolute;
		top: calc(50% - 20px);
		font-size: 40px;
		background-color: transparent;
		color: $blue;
		border: none;
		padding: 0;
		cursor: pointer;
		z-index: 99;

		i {
			font-weight: 400;
		}

		&.slick-prev {
			left: 0;
		}

		&.slick-next {
			right: 0;
		}

		@include tablet-up {
			font-size: 50px;
		}
	}
}

.newsletter-alert {
	font-size: 14px;
	display: none;

	&.error {
		margin-top: 10px;
	}
}

header {
	background-color: #fbfbfb;

	#hero {
		position: relative;

		.bg {
			width: 100%;
			height: auto;
			min-height: 200px;

			@include tablet-down {
				object-fit: cover;
				// object-position: 80% 0;
				// padding-top: rems(60);
			}

			&.desktop {
				@include phone-down {
					display: none;
				}
			}

			&.mobile {
				@include tablet-up {
					display: none;
				}
			}

		}

		.hero-content {
			position: absolute;
			right: 26%;
			top: 72%;
			color: $black;
			max-width: rems(300);
			display: inline-block;

			.logo {}

			p {
				font-size: rems(20);
				padding-bottom: rems(10);
			}

			a {
				padding: rems(12) rems(36);
				background-color: $black;
				border: 1px solid $black;
				font-size: rems(20);

				@include hover-focus {
					background-color: $white;
					color: $black;
				}
			}
		}

		.social {
			@include flex(row, center, center);
			gap: 20px;
			position: absolute;
			left: 0;
			top: calc(10% - 40px);
			width: 100%;


			a {
				display: block;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: $white;
				color: $black;

				@include flex(column, center, center);
				font-size: 15px;
			}
		}

		@include tablet-up {
			.logo {
				height: 7vw;
				left: 50px;
				top: calc(40% - 40px);
			}

			.hero-content {
				position: absolute;
				right: 26%;
				top: 72%;
				color: $black;
				max-width: none;

				.logo {}

				p {
					font-size: rems(33);
				}

				a {
					padding: rems(12) rems(36);
					background-color: $black;
					border: 1px solid $black;

					@include hover-focus {
						background-color: $white;
						color: $black;
					}
				}
			}

			.social {
				left: 8.8%;
				gap: 30px;
				justify-content: flex-start;

				a {
					width: 40px;
					height: 40px;
					font-size: 25px;
				}
			}
		}
	}

	#nav {
		background-color: $blue;
		padding: 15px;

		.menu {
			@include flex(column, center, center);
			display: none;
			gap: 30px;

			a {
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 600;
				padding: 10px;
			}

			&.active {
				display: flex;
			}
		}

		@include tablet-up {
			.menu {
				@include flex(row, center, center);
				gap: 50px;
				text-align: center;

				a {
					padding: 0;

					@include hover-focus {
						color: $gray-light;
					}
				}
			}
		}
	}

}

main {

	#music {
		.content {
			padding: 40px 0;

			.container {
				.slider-music {
					padding: 0 40px;

					.music-item {
						.split {
							.side {
								text-align: center;
								gap: 10px;

								>img {
									width: 100%;
									max-width: 500px;
									margin: 0 auto;
								}

								.type {
									font-size: 14px;
									text-transform: uppercase;
									color: $gray;
								}

								.title {
									font-size: 25px;
									font-weight: 700;
									color: $black;
								}

								.links {
									@include flex(row, center, center);
									gap: 10px;
									padding: 10px 0;

									a {
										width: 30px;
										height: 30px;
										background-color: $blue;
										border-radius: 50%;
										@include flex(column, center, center);
										font-size: 15px;

										&.pandora {
											padding: 0;
											background-color: transparent;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		@include tablet-up {
			.content {
				padding: 100px 0;

				.container {
					.slider-music {
						padding: 0 60px;

						.music-item {
							.split {
								.side {
									max-width: 50%;

									&:first-child {
										a {
											margin-left: auto;
											display: block;
											max-width: 390px;

											img {
												width: 100%;
											}
										}
									}

									&:last-child {
										@include flex(column, center, flex-start);
										gap: 0;
										padding-left: 40px;

										.links {
											padding-top: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	#about {
		background-color: $blue;

		.bg {
			display: none;
		}

		.content {
			padding: 40px 0;

			.split {
				.side {
					h2 {
						text-transform: uppercase;
						font-size: 30px;
						margin-bottom: 20px;
					}

					.scroll-wrapper {
						height: 600px;
						overflow-y: scroll;

						p {
							font-size: 16px;
							line-height: 1.6;
						}
					}
				}
			}
		}

		@include tablet-up {
			.bg {
				display: block;
				object-fit: cover;
				width: 60%;
				height: 100%;
				object-position: right;
			}

			.content {
				padding: 140px 0;

				.split {
					.side {
						.scroll-wrapper {
							height: 600px;
							overflow-y: scroll;

							p {
								max-width: 480px;
								line-height: 2;
							}
						}
					}
				}
			}
		}
	}

	#videos {
		background-color: $black;

		.content {
			padding: 80px 0;

			h2 {
				font-size: 30px;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 40px;
			}

			.container {
				iframe {
					width: 100%;
					aspect-ratio: 6/3.5;
				}

				.slider-videos {
					margin-top: 20px;
					padding: 0 40px;

					.slick-list {
						margin-left: -10px;

						.slick-slide {
							margin-left: 10px;
						}
					}

					.slick-arrow {
						color: $white;

						i {
							font-weight: 400;
						}
					}
				}
			}
		}

		@include tablet-up {
			.content {
				padding: 160px 0;

				.container {
					.slider-videos {
						margin-top: 60px;
						padding: 0 60px;
					}
				}
			}
		}
	}

	#tour {
		background-color: $white;
		padding: 40px 0;
		color: $black;

		.container {
			h2 {
				text-align: center;
				font-size: 30px;
				text-transform: uppercase;
				margin-bottom: 30px;
			}

			#events {
				@include flex(column, initial, initial);
				gap: 30px;

				.event {
					@include flex(column, center, center);
					gap: 10px;
					text-align: center;

					.event-date {
						font-size: 18px;
						font-weight: 700;
						text-transform: uppercase;
					}

					.event-info {
						@include flex(column, center, center);
						gap: 6px;

						.event-location {
							font-weight: 700;
							font-size: 18px;
						}
					}
				}
			}
		}

		@include tablet-up {
			padding: 140px 0;

			.container {
				#events {
					align-items: center;

					.event {
						@include flex(row, space-between, center);
						text-align: left;
						width: 100%;
						max-width: 800px;

						.event-date {
							flex: 1;
						}

						.event-info {
							flex: 2;
							@include flex(column, flex-start, initial);
						}

						.event-links {
							flex: 2;
							@include flex(row, flex-end, center);
							gap: 10px;
						}
					}
				}
			}
		}
	}

	section#merch {
		padding: rems(200) 0 0;

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;


			.btn {
				background-color: $blue;
				margin: rems(40) 0;

				@include hover-focus {
					background-color: $gray;
				}
			}
		}
	}

	#shop {
		padding: 40px 0;
		background-color: $white;
		color: $black;

		.container {
			text-align: center;

			h2 {
				font-size: 30px;
				margin-bottom: 30px;
				text-align: center;
				text-transform: uppercase;
			}

			.split {
				margin-bottom: 40px;

				.side {
					a {
						@include flex(column, center, center);
						gap: 10px;
						color: $black;
						text-align: center;

						img {
							width: 100%;
							max-width: 300px;
						}

						.title {
							font-weight: 700;
							font-size: 18px;
						}

						.price {
							font-size: 14px;
						}
					}
				}
			}
		}

		@include tablet-up {
			padding: 0 0 120px 0;
		}
	}

}

footer {
	background-image: url('../../dist/img/image-footer-bg.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 30px 0;

	.container {
		.split {
			margin-bottom: 30px;
			gap: 40px;

			.side {
				text-align: center;

				.logo {
					display: inline-block;
					margin-bottom: 40px
				}

				.social {
					@include flex(row, center, center);
					gap: 20px;

					a {
						display: block;
						width: 30px;
						height: 30px;
						border-radius: 50%;
						background-color: $white;
						color: $blue;
						@include flex(column, center, center);
						font-size: 15px;
					}
				}

				form {
					@include flex(column, initial, initial);
					gap: 10px;

					.form-row {
						@include flex(column, initial, initial);
						gap: 4px;

						label {
							text-align: left;
						}

						input {
							width: 100%;
							padding: 10px;
							border: none;
							border-radius: 0;
							border: solid 1px $white;

							&:focus {
								outline: none;
								border-color: $black;
							}
						}
					}
				}
			}
		}

		.copyright {
			text-align: center;

			p {
				text-transform: uppercase;
				font-size: 12px;
				line-height: 1.4;
			}
		}
	}

	@include tablet-up {
		padding-top: 100px;

		.container {
			.split {
				margin-bottom: 60px;
				align-items: center;
				justify-content: center;

				.side {
					max-width: 400px;

					.social {
						gap: 30px;

						a {
							width: 40px;
							height: 40px;
							font-size: 25px;
						}
					}
				}
			}

			.copyright {}
		}
	}
}